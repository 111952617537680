@import '/public/sass/variables';
@import '/public/sass/mixins';

.header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: min(90%, 1600px);
    margin-inline: auto;
    padding-block: 10dvh;

    & .headerContent {
        display: grid;
        grid-template-areas: "stack";

        & .headerContentBlobs {
            grid-area: stack;
            z-index: -1;
            position: relative;

            & svg {
                width: 350px;
                opacity: 15%;
                position: absolute;

                &:first-child {
                    top: -25%;
                    right: 0;
                    transform: rotateX(-15deg);
                }

                &:last-child {
                    bottom: 5%;
                    left: -15%;
                    transform: rotateX(15deg);
                }
            }
        }

        & .headerContentText {
            grid-area: stack;
            padding-right: 2rem;
            padding-top: 2rem;

            & h1 {
                margin: 0;
                font-size: clamp($fs-4l, 8vw, $fs-5l);
                font-weight: 900;
                text-wrap: balance;
                line-height: 1em;
                letter-spacing: -1px;

                & i {
                    font-style: normal;
                    color: transparent;
                    background-image: linear-gradient(to right, $c-red, adjust-color($c-red, $lightness: 25%), $c-red, adjust-color($c-red, $lightness: 25%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-size: 300%;
                    background-position-X: 0;
                    animation: gradient 2500ms infinite linear;

                    @media (prefers-reduced-motion: reduce) {
                        animation: none;
                    }

                    @keyframes gradient {
                        from {
                            background-position-x: 0;
                        } to {
                            background-position-x: 100%;
                        }
                    }
                }
            }

            & p {
                font-size: $fs-lg;
                margin-block: 2rem;
            }

            & .headerContentButtons {
                display: flex;
                gap: 1rem;
            }
        }
    }

    & .headerImage {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);

        & img {
            @media (prefers-reduced-motion: no-preference) {
                animation: float 7500ms infinite ease-in-out;
            }
        }

        & img:first-child {
            grid-column: 1 / 2;
            grid-row: 1 / 4;
        }

        & img:last-child {
            grid-column: 2 / 3;
            grid-row: 3 / 6;
            animation-delay: 2500ms;
        }

        @keyframes float {
            0% {
                translate: 0 0;
            } 50% {
                translate: 0 15px;
            } 100% {
                translate: 0 0;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        grid-template-columns: 3fr 2fr;

        & .headerContent {
            & .headerContentBlobs {
                & svg {
                    &:first-child {
                        top: -25%;
                        right: 0;
                    }
                    &:last-child {
                        bottom: -30%;
                        left: -15%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;
        width: calc(100% - 2rem);

        & .headerImage {
            width: 75%;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr;
            column-gap: 2rem;

            & img {
                grid-column: unset !important;
                grid-row: unset !important;
            }
        }

        & .headerContent {
            & .headerContentBlobs {
                & svg {
                    &:first-child {
                        top: -25%;
                        right: 0;
                    }
                    &:last-child {
                        bottom: -20%;
                        left: -5%;
                    }
                }
            }

            & .headerContentText {
                padding: 0;

                & h1 {
                    text-align: center;
                }

                & p {
                    text-align: center;
                    width: 75%;
                    margin-inline: auto;
                    text-wrap: balance;
                }

                & .headerContentButtons {
                    justify-content: center;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        & .headerContent {
            & .headerContentBlobs {
                & svg {
                    width: 250px;
                }
            }

            & .headerContentText {
                & p {
                    width: 100%;
                }
            }
        }
    }
}