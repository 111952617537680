@import '/public/sass/variables';
@import '/public/sass/mixins';

.card {
    border: 1px solid $c-gray-100;
    border-radius: $br-md;
    animation: load $trans-time-sm;
    scale: 1;

    & .header {
        background-color: $c-gray-100;
        padding: .5rem 1rem;
        display: flex;
        gap: .5rem;
        align-items: center;
        cursor: move;
        border-radius: calc($br-md - 2px) calc($br-md - 2px) 0 0;

        & .index {
            color: $c-gray-300;
        }

        & svg {
            margin-left: auto;
            color: $c-gray-300;
            cursor: pointer;
            transition: color $trans-time-sm;
            font-size: $fs-sm;

            &:hover {
                color: $c-red;
            }
        }
    }

    & .body {
        padding: 2rem;
        padding-top: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;

        & .term {
            display: flex;
            gap: .25rem;
            align-items: center;

            & .termInput {
                padding: .5rem;
                border: none;
                border-bottom: 2px solid $c-gray-100;
                border-radius: 0;
                font-size: $fs-md;
                background-color: transparent;
                transition: border-color $trans-time-sm;
                width: 100%;
    
                &:focus {
                    outline: none;
                    border-bottom-color: $c-blue-dark;
                }
            }

            & .fileInput {
                cursor: pointer;

                & input {
                    display: none;
                }

                & svg {
                    font-size: 1.25rem;
                    color: $c-black;
                    transition: color $trans-time-sm;

                    &.imageUploaded {
                        color: $c-red;
                    }
                }
            }

            & .imagePreview {
                display: grid;
                grid-template-columns: 3fr 1fr;
                align-items: center;
                gap: .25rem;

                & .image {
                    height: 50px;
                    aspect-ratio: 2/1;
                    object-fit: cover;
                    border-radius: $br-sm;
                }

                & .removeImage {
                    cursor: pointer;
                    transition: color $trans-time-sm;

                    &:hover {
                        color: $c-red;
                    }
                }
            }
        }

        @media screen and (max-width: 640px) {
            grid-template-columns: 1fr;
            row-gap: 1rem;
        }
    }

    &.dragging {
        opacity: .5;
    }

    &.deleting {
        animation: delete 350ms forwards;
    }

    @keyframes load {
        0% {
            scale: .95;
        }
        100% {
            scale: 1;
        }
    }

    @keyframes delete {
        0% {
            max-height: 150px;
            opacity: 1;
            margin: 0;
        }
        10% {
            opacity: 0;
        }
        100% {
            max-height: 0px;
            opacity: 0;
            margin-bottom: -1rem;
        }
    }
}