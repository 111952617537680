@import '/public/sass/variables';

.nav {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: .5rem;
    max-height: 50dvh;

    & .item {
        display: flex;
        gap: .5rem;
        align-items: center;
        border-radius: $br-lg;
        padding: 1rem 2rem;
        font-size: $fs-lg;
        color: $c-black;
        font-weight: 600;
        text-decoration: none;
        background-color: $c-accent-white;
        cursor: pointer;
        transition: background-color $trans-time-sm, color $trans-time-sm;
        position: relative;
        overflow: hidden;

        & svg {
            min-width: 1.5em;
        }

        &:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 100%;
            background-color: $c-gray-100;
            bottom: 0;
            left: 0;
            transition: width $trans-time-sm;
        }
        &:hover:after, &.selected:after {
            width: 15px;
        }
        &:nth-of-type(1) {
            &:hover, &.selected {
                color: $c-aqua-dark;
            }

            &:after {
                background-color: $c-aqua;
            }
        }
        &:nth-of-type(2) {
            &:hover, &.selected {
                color: $c-yellow-dark;
            }

            &:after {
                background-color: $c-yellow;
            }
        }
        &:nth-of-type(3) {
            &:hover, &.selected {
                color: $c-pink-dark;
            }

            &:after {
                background-color: $c-pink;
            }
        }
        &:nth-of-type(4) {
            &:hover, &.selected {
                color: $c-green-dark;
            }

            &:after {
                background-color: $c-green;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .nav {
        & .item {
            font-size: $fs-md;
        }
    }
}

@media screen and (max-width: 900px) {
    .nav {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        & .item {
            border-radius: $br-md;
        }
    }
}