@import '/public/sass/variables';
@import '/public/sass/mixins';

.inputWrapper {
    display: grid;
    text-align: left;
}

.label {
    @include normal-label;
}

.iconInput {
    display: flex;
    align-items: center;
    cursor: text;
    padding-left: .5rem;
    column-gap: .25rem;
    
    @include normal-input;

    &:focus-within {
        outline: 2px solid adjust-color($color: $c-blue-dark, $alpha: 0.5);
    }
}

.input {
    border: none;
    background-color: transparent;
    font-size: $fs-md;
    outline: none;
    width: 100%;
}

.iconWrapper {
    pointer-events: none;
    
    @include center-content;
}
.iconWrapper > svg {
    color: $c-gray-200;
}