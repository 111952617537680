@import '/public/sass/variables';

.container {
    background-color: $c-white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100dvh;
    overflow-x: auto;

    & .testNav {
        position: fixed;
        background-color: $c-accent-white;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 2rem;
        z-index: 1;

        & .back {
            cursor: pointer;
        }

        & span {
            font-weight: 700;
        }
    }

    & .test {
        width: $w-sm;
        margin-inline: auto;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%);

        & .progress {
            display: flex;
            gap: 2px;
            width: 100%;
            border-radius: 100vw;
            overflow: hidden;
            margin-bottom: 1rem;
    
            & .progressDiv {
                background-color: $c-gray-100;
                width: 100%;
                height: 15px;
    
                &.correct {
                    background-color: $c-green-dark;
                }
    
                &.incorrect {
                    background-color: $c-red;
                }
            }
        }
    
        & .definition {
            background-color: $c-accent-white;
            display: block;
            width: 100%;
            padding: 1rem;
            border-radius: $br-sm;
            margin-bottom: 2rem;
        }
    
        & .input {
            width: 100%;
            padding: .5rem 1rem;
            border: none;
            border-bottom: 3px solid $c-blue-dark;
            border-radius: 0;
            background: transparent;
            font-size: $fs-lg;
            outline: none;
            margin-bottom: 1rem;
        }
    
        & .buttonWrapper {
            display: flex;
            justify-content: space-between;
        }

        & .resultsTitle {
            display: flex;
            gap: 1rem;
            align-items: center;
        }
    
        & .results {
            padding-bottom: 2rem;

            & .round {
                border: 2px solid $c-accent-white;
                padding: 1rem;
                border-radius: $br-md;
                margin-top: 1rem;
    
                & strong {
                    display: block;
                    margin-bottom: .5rem;
                }
    
                & .term {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: $c-red;
    
                    &.correct {
                        color: $c-green-dark;
                    }
                }
            }
        }
    }
    
}