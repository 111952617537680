@import '/public/sass/variables';
@import url('https://fonts.googleapis.com/css2?family=Alata&family=Albert+Sans:ital,wght@0,100..900;1,100..900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Figtree:ital,wght@0,300..900;1,300..900&family=Geologica:wght@100..900&family=Golos+Text:wght@400..900&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Jost:ital,wght@0,100..900;1,100..900&family=League+Spartan:wght@100..900&family=Lexend:wght@100..900&family=Onest:wght@100..900&family=Sen:wght@400..800&family=Sora:wght@100..800&family=Varela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');

// Font: Gabarito
@font-face {
    font-family: Gabarito;
    src: url('../public/assets/fonts/Gabarito-VariableFont_wght.ttf');
}

// Global styles
* {
    box-sizing: border-box;
    font-family: 'Onest', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $c-black;
}

// Images and graphics
img, svg, picture {
    max-width: 100%;
}

// Headers and paragraphs
h1 {
    margin: 0;
    font-size: 2rem;
    line-height: 1.2em;
}

h2 {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.2em;
}

p {
    line-height: 1.5em;
}

// Page
#root > div {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}

// Links
a {
    color: $c-black;
}

// Lazy loading
.LazyLoad {
    opacity: 0;
    transition: opacity $trans-time-lg;
}

.LazyLoad.is-visible {
    opacity: 1;
}

// Popups
.popup-content {
    animation: fade-in 100ms;
    box-shadow: none !important;
    
    &[role="tooltip"] {
        width: max-content !important;
        user-select: none;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        } to {
            opacity: 1;
        }
    }
}