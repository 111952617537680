@import '/public/sass/variables';

.button {
    background-color: transparent;
    padding: .5rem 1rem;
    border: none;
    border-radius: $br-sm;
    color: $c-black;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color $trans-time-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    &:hover {
        background-color: rgba($color: #000, $alpha: 0.1);
    }

    &:disabled {
        background-color: adjust-color($color: $c-red, $saturation: -50%);
        cursor: not-allowed;
    }
}