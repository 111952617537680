@import '/public/sass/variables.scss';

.header {
    display: flex;
    align-items: center;

    & h2 {
        margin-right: auto;
    }
}

.loader {
    padding-block: 10rem;
    width: max-content;
    margin-inline: auto;
}