@import '/public/sass/variables';
@import '/public/sass/mixins';

.card {
    padding: 2rem;
    background-color: $c-info;
    border-radius: $br-md;
    cursor: pointer;
    transition: translate $trans-time-sm, box-shadow $trans-time-sm;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    scale: 0.95;
    animation: load $trans-time-md forwards;

    @for $i from 1 through 99 {
        &:nth-child(#{$i}) {
            animation-delay: 100ms * $i;
        }
    }

    @keyframes load {
        to {
            opacity: 1;
            scale: 1;
        }
    }

    &:hover {
        translate: 0 -5%;
        box-shadow: 0 5px 20px rgba($color: #02035f, $alpha: .15);
    }

    & > .header {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: .25rem;

        & > .title {
            font-size: $fs-lg;
            font-weight: 700;
            line-height: 1.5em;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & > .subject {
            font-size: $fs-sm;
            font-weight: 700;
            border: 2px solid $c-black;
            border-radius: 100vw;
            padding-inline: .75rem;
            line-height: 1.5em;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: max-content;
        }
    }

    & > .creator {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        gap: .5rem;
    }

    & > .manage {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        & > .item {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 5px;
            transition: color $trans-time-sm;

            & > span {
                display: flex;
                align-items: center;
            }

            &:last-child {
                margin-left: auto;
            }

            &:hover {
                color: $c-red;
            }
        }
    }
}