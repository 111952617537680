@import '/public/sass/variables';

.errorPage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5dvw;
    width: min(100% - 4rem, 1200px);
    margin: auto;

    & .errorPageText {
        & .errorPageError {
            display: block;
            margin-bottom: 1rem;
            position: relative;
            padding-bottom: .25rem;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40px;
                height: 1px;
                background-color: $c-black;
            }
        }

        & .errorPageTitle {
            font-size: 4rem;
            font-weight: 900;
            max-width: 12ch;
            text-wrap: balance;
            line-height: 1em;
        }

        & .errorPageParagraph {
            font-size: $fs-lg;
            margin-top: 2rem;
        }
    }

    @media screen and (max-width: 1200px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 3rem;

        & .errorPageText {
            & .errorPageTitle {
                font-size: 3rem;
            }

            & .errorPageParagraph {
                max-width: 500px;
            }
        }

        & .errorPageImage {
            & div {
                width: min(100% - 4rem, 400px);
                margin-inline: auto;
            }
        }
    }
}