@import '/public/sass/variables';

.rating {
    display: flex;
    align-items: center;
    margin-right: .5rem;

    & svg {
        color: $c-gray-100;
    }
}

.filled {
    color: $c-yellow !important;
}