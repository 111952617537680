@import '/public/sass/variables';

.inputWrapper {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem;
    border: 1px solid $c-gray;
    border-radius: 100vw;
    background-color: $c-white;

    &:focus-within {
        outline: 2px solid adjust-color($color: $c-blue-dark, $alpha: 0.5);
    }

    & > .input {
        border: none;
        background-color: transparent;
        font-size: $fs-lg;
        outline: none;
        width: 100%;
    }

    & > svg {
        color: $c-gray-200;
    }
}