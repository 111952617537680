@import '/public/sass/variables';

.card {
    padding: 1.5rem;
    border-radius: $br-md;
    transition: background-color $trans-time-sm;
    position: relative;
    background-color: $c-white;
    opacity: 0;
    scale: 0.95;
    animation: load $trans-time-md forwards;

    @for $i from 1 through 99 {
        &:nth-child(#{$i}) {
            animation-delay: 100ms * $i;
        }
    }

    @keyframes load {
        to {
            opacity: 1;
            scale: 1;
        }
    }
    
    & .body {
        display: flex;
        align-items: center;

        & .header {
            display: flex;
            align-items: center;
            gap: 1rem;
            
            & .icon {
                color: $c-red;
                font-size: $fs-lg;
                min-width: 32px;
            }
            
            & .titles {
                display: grid;
                grid-template-rows: 1fr 1fr;
                
                & .title {
                    font-size: $fs-lg;
                    font-weight: 700;
                    text-wrap: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
                & .date {
                    color: $c-gray-300;
                    margin-top: 3px;
                }
            }
    
        }
    
        & .actions {
            display: flex;
            margin-left: auto;
            align-items: center;
    
            & div {
                display: flex;
                gap: 1rem;
                align-items: center;
    
                & svg {
                    cursor: pointer;
                    font-size: $fs-lg;
                    color: $c-red;
                    transition: color $trans-time-sm;
                    min-width: 32px;

                    &:hover {
                        color: $c-red-dark;
                    }
                }
            }

            & a {
                display: grid;
                place-items: center;
            }
    
            & .liveActions {
                & .live {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    font-size: $fs-sm;
                    line-height: 1em;
                    color: $c-white;
                    background-color: $c-red;
                    border-radius: $br-sm;
                    font-weight: 700;
        
                    &::before {
                        content: '';
                        width: .75em;
                        aspect-ratio: 1 / 1;
                        background-color: $c-white;
                        margin-right: 5px;
                        border-radius: 100%;
                        animation: live-flash 2s ease-in-out infinite;
            
                        @keyframes live-flash {
                            0% {
                                background-color: $c-red;
                            }
                            50% {
                                background-color: $c-white;
                            }
                            100% {
                                background-color: $c-red;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 640px) {
            flex-direction: column;
            align-items: start;
            gap: 2rem;

            & .actions {
                margin-left: 0;
            }
        }
    }
}