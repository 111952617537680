@import '/public/sass/variables';

.sidebar {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
    width: $width-dashboard-sidebar;
    position: fixed;
    top: $height-navbar;
    background: $c-white;
    margin: 1rem;
    border-radius: $br-md;
    transition: transform $trans-time-md;
    z-index: 1;

    & > .item {
        display: flex;
        align-items: center;
        column-gap: .5rem;
        line-height: 1em;
        font-size: $fs-md;
        padding: .5rem 1rem;
        cursor: pointer;
        transition: background-color $trans-time-sm;
        border-radius: $br-sm;
        text-decoration: none;

        &:hover {
            background-color: $c-accent-white;
        }

        &.selected {
            background-color: $c-red;
            color: $c-white;
        }

        & svg {
            min-width: 32px;
        }

        &.commingSoon::after {
            content: 'KOMMER SNART';
            right: 0;
            font-size: .6rem;
            line-height: 1em;
            font-weight: 700;
            color: $c-red;
            margin-left: auto;
            padding-top: 4px;
        }
    }

    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        display: none;
    }

    @media screen and (max-width: $bp-dashboard-sidebar) {
        transform: translateX(calc(-100% - 1rem));
        padding-top: 3rem;

        &.open {
            transform: translateX(0);
        }

        .close {
            display: block;
        }
    }
}