@import '/public/sass/variables';

.container {
    border: 1px solid $c-gray-100;
    border-top: 10px solid $c-red;
    border-radius: $br-md;
    margin-top: 4rem;
    padding: 2rem;

    & .question {
        & .answerWrapper {
            margin-top: 2rem;
    
            & .answerExactOpen {
                & textarea {
                    width: 100%;
                    height: calc((.5rem * 2) + (1.5em * 3));
                    border-radius: $br-sm;
                    padding: .5rem 1rem;
                    font-size: $fs-md;
                    resize: vertical;
                    border: 1px solid $c-gray-100;
    
                    &:focus {
                        outline: 2px solid $c-blue-dark;
                    }
                }
            }
    
            & .answerMultiplechoice, .answerCheckboxes {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-left: 2rem;
    
                & .alternative {
                    display: flex;
                    align-items: center;
                    gap: 1ch;
                    font-size: $fs-lg;
                    cursor: pointer;
    
                    & .radio {
                        display: block;
                        width: 22px;
                        aspect-ratio: 1 / 1;
                        background-color: transparent;
                        border: 2px solid $c-red;
                        border-radius: 100%;
                        position: relative;
    
                        &::after {
                            content: '';
                            position: absolute;
                            width: calc(100% - 6px);
                            aspect-ratio: 1 / 1;
                            background-color: transparent;
                            border-radius: 100%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: background-color $trans-time-sm;
                        }
                    }
    
                    &:hover .radio::after {
                        background-color: adjust-color($c-red, $alpha: -0.7);
                    }
                    &.selected .radio::after {
                        background-color: $c-red;
                    }
    
                    & .checkbox {
                        display: grid;
                        place-items: center;
                        width: 22px;
                        aspect-ratio: 1 / 1;
                        background-color: transparent;
                        border: 2px solid $c-red;
                        border-radius: $br-sm;
                        position: relative;
                        color: transparent;
                        transition: background-color $trans-time-sm;
    
                        & svg {
                            line-height: 0;
                            width: 18px;
                            height: 18px;
                        }
                    }
    
                    &:hover .checkbox {
                        background-color: adjust-color($c-red, $alpha: -0.8);
                    }
                    &.selected .checkbox {
                        background-color: $c-red;
                        color: $c-white;
                    }
                }
    
            }
        }

        &.next {
            animation: next 100ms ease-out;

            @keyframes next {
                0% { transform: translateX(25px); opacity: 0; }
                100% { transform: translateX(0); opacity: 1; }
            }
        }

        &.previous {
            animation: previous 100ms ease-out;

            @keyframes previous {
                0% { transform: translateX(-25px); opacity: 0; }
                100% { transform: translateX(0); opacity: 1; }
            }
        }
    }

    & .navigation {
        margin-top: 2rem;
        padding-top: 1rem;
        border-top: 1px solid $c-gray-100;
        display: flex;
        justify-content: space-between;

        & svg {
            color: $c-red;
            width: 40px;
            height: 40px;
            cursor: pointer;

            &:hover {
                color: $c-red-dark;
            }
        }
    }
}

.freezeModalContent {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-wrap: balance;

    & .illustration {
        width: min(90%, 150px);
        margin-bottom: 2rem;
    }

    & span {
        margin-top: 1rem;
        line-height: 1.5em;
    }
}