@import '/public/sass/variables';

.trigger {
    position: relative;

    .count {
        position: absolute;
        background-color: $c-red;
        color: $c-white;
        line-height: 1em;
        display: grid;
        place-content: center;
        min-width: 18px;
        aspect-ratio: 1 / 1;
        border-radius: 100vw;
        font-size: $fs-xs;
        top: -10px;
        right: -10px;
    }
}

.title {
    margin-bottom: 1rem;
}

.saveWrapper {
    position: relative;
    padding-top: 2rem;
    
    .saveGrid {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        max-height: 60dvh;
        overflow-y: auto;
    }

    .save {
        position: relative;
        padding: 1.5rem 1rem;
        background-color: $c-white;
        border-radius: $br-md;
        display: flex;
        column-gap: 1rem;
        align-items: center;

        .saveHeader {
            display: flex;
            align-items: center;
            gap: 1rem;
            overflow-x: hidden;

            .saveIcon {
                color: $c-red;
                font-size: $fs-lg;
                min-width: 32px;
            }
    
            .saveContent {
                display: flex;
                gap: 1rem;
                align-items: center;
    
                .saveDate {
                    font-size: $fs-lg;
                    overflow-x: hidden;
                }
        
                .saveTag {
                    display: inline-block;
                    background-color: $c-info;
                    padding: .15rem .5rem;
                    border-radius: $br-sm;
                    min-width: max-content;
                }
            }
        }

        .saveActions {
            display: flex;
            gap: .75rem;
            align-items: center;
            margin-left: auto;

            svg {
                font-size: $fs-lg;
                cursor: pointer;
                color: $c-red;
                min-width: 32px;
            }
        }

        @media screen and (max-width: 640px) {
            .saveHeader .saveContent .saveTag {
                display: none;
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: calc(100% + 4rem);
        height: calc(100% + 2rem);
        background-color: $c-backdrop-white;
        top: 0;
        left: -2rem;
    }
}

.loading {
    width: 100%;
    display: grid;
    justify-content: center;
    padding-block: 2rem;
}

.noSaves {
    display: block;
    text-align: center;
    width: 100%;
    font-size: $fs-lg;
}

.close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}