@import '/public/sass/variables';

.navbar {
    height: $height-navbar;
    padding-block: 1rem;

    .logo {
        width: 150px;
    }

    .content {
        width: $w-lg;
        display: flex;
        align-items: center;
        height: 100%;
        margin-inline: auto;
    
        .create {
            margin-left: auto;
            width: 38px;
            height: 38px;
            cursor: pointer;
            color: $c-red;
            transition: scale $trans-time-sm;
        
            &:hover {
                scale: 1.05;
            }
        }
    
        .profile {
            cursor: pointer;
            margin-left: 1rem;
        }

        .links {
            display: flex;
            gap: 1rem;
            margin-left: auto;
        }
    }
}

// Hamburger menu
.hamburger {
    display: none;
    margin-left: auto;
}

.hamburgerMenu {
    background-color: $c-accent-white;
    display: none;
    gap: 1rem;
    justify-content: end;
    padding: 2rem 1rem;
    position: absolute;
    width: 100%;
    margin-top: $height-navbar;
    transition: translate $trans-time-sm ease-in-out, opacity $trans-time-sm ease-in-out;
    z-index: 1;

    &:not(.open) {
        opacity: 0;
        translate: 0 -10%;
    }
}

@media screen and (max-width: 640px) {
    .navbar .content .links {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .hamburgerMenu {
        display: grid;
    }
}