@import '/public/sass/variables';

.questionBox {
    background-color: adjust-color($c-blue-dark, $lightness: 10%);
    padding: 2rem 3rem;
    border-radius: $br-lg;
    position: relative;
    overflow: hidden;

    & .title {
        font-size: $fs-lg;
        margin-bottom: 1rem;
    }

    & .answer {
        & .answerType {
            display: block;
            margin-bottom: .5rem;

            &.correctAnswer {
                margin-top: 1rem;
            }
        }

        & .answerWrapper {
            & .inputAnswerWrapper {
                display: grid;
                gap: .5rem;
            }

            & .exactAnswer {
                background-color: $c-white;
                border-radius: $br-sm;
                padding: .5rem 1rem;
                width: 100%;
                line-height: 1.5em;
            }

            & .openAnswer {
                background-color: $c-white;
                border-radius: $br-sm;
                padding: .5rem 1rem;
                width: 100%;
                line-height: 1.5em;
            }

            & .mcAnswerWrapper, & .checkboxAnswerWrapper {
                padding-left: 1.5rem;
                display: grid;
                gap: .5rem;

                & .mcAnswer {
                    display: grid;
                    grid-template-columns: 1rem 1fr;
                    column-gap: .75rem;
    
                    & .radio {
                        display: inline-block;
                        width: 1rem;
                        aspect-ratio: 1 / 1;
                        border-radius: 100%;
                        background-color: transparent;
                        border: 2px solid $c-red;
                        position: relative;
                        margin-top: 2px;
    
                        &.checked {
                            &::after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 8px;
                                aspect-ratio: 1 / 1;
                                border-radius: 100%;
                                background-color: $c-red;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }

                & .checkboxAnswer {
                    display: grid;
                    grid-template-columns: 1rem 1fr;
                    column-gap: .75rem;
    
                    & .checkbox {
                        display: inline-block;
                        width: 1rem;
                        aspect-ratio: 1 / 1;
                        border-radius: 4px;
                        background-color: transparent;
                        border: 2px solid $c-red;
                        position: relative;
                        margin-top: 2px;
    
                        &.checked {
                            background-color: $c-red;
                        }

                        & svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: $c-white;
                        }
                    }
                }
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 12px;
        background-color: $c-blue-dark;
    }

    &.correct {
        background-color: $c-success;

        &::after {
            background-color: $c-success-dark;
        }
    }

    &.incorrect {
        background-color: adjust-color($c-red, $lightness: 25%);

        &::after {
            background-color: $c-red;
        }
    }

    @media screen and (max-width: 640px) {
        padding: 2rem 1.5rem;
    }
}