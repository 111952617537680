@import '/public/sass/variables';
@import '/public/sass/mixins';

.noContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 3rem;

    & > .lazyLoad {
        width: clamp(250px, 40%, 500px);
        margin-bottom: 2rem;
        
        & > img {
            width: min(100%, 200px);
        }
    }

    @media screen and (max-width: 700px) {
        .noContent {
            & > h1 {
                font-size: $fs-lg;
            }
        }
    }
}

// Nav and title
.navTitle {
    display: flex;
    gap: 1rem;
    align-items: center;

    & svg {
        cursor: pointer;

        @media screen and (min-width: $bp-dashboard-sidebar) {
            display: none;
        }
    }
}

// Test
.testCardWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}