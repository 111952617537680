@import '/public/sass/variables';

.dropdown {
    display: flex;
    flex-direction: column;
    width: max-content;
    border-bottom: 1px solid $c-gray-200;

    & > .input {
        display: none;
    }

    & > .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .5rem;
        cursor: pointer;
        margin-bottom: .5rem;

        & > .title {
            font-weight: 700;
        }

        & > .arrow {
            transition: transform $trans-time-sm;
        }
    }
    
    & > .body {
        visibility: hidden;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        padding-inline: .5rem;
        padding-bottom: .5rem;
        max-height: 0;
    }
    & > .input:checked ~ .body {
        visibility: visible;
        max-height: 100vh;
    }

    & > .input:checked ~ .button > .arrow {
        transform: rotate(180deg);
    }
}

.fill {
    width: 100%;
}