@import '/public/sass/variables';
@import '/public/sass/mixins';

.inputWrapper {
    display: grid;
    text-align: left;
}

.label {
    @include normal-label;
}

.input {
    @include normal-input;
}