@import '/public/sass/variables';

.flashcards {
    & .main {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-gap: 1rem;
    }

    & .container {
        perspective: 1000px;
        display: grid;

        & .flashcard {
            background-color: $c-blue;
            padding: 1rem;
            border-radius: $br-lg;
            min-height: 50dvh;
            cursor: pointer;
            transform-style: preserve-3d;

            &.flipping {
                animation: flip 350ms ease-in-out;

                @keyframes flip {
                    0% {
                        transform: rotateX(0);
                    }
                    100% {
                        transform: rotateX(180deg);
                    }
                }
            }

            &.nexting {
                animation: next 150ms ease-out;

                @keyframes next {
                    0% { transform: translateX(50px); }
                    100% { transform: translateX(0); }
                }
            }

            &.preving {
                animation: prev 150ms ease-out;

                @keyframes prev {
                    0% { transform: translateX(-50px); }
                    100% { transform: translateX(0); }
                }
            }
        }

        & .flashcardContent {
            position: absolute;
            place-self: center;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            max-width: 100%;
            max-height: 50dvh;
            overflow: hidden;
            padding: 1rem;
            
            & .term {
                font-size: $fs-xxl;
                text-align: center;
            }
    
            & .image {
                width: 60%;
                aspect-ratio: 2/1;
                object-fit: cover;
                border-radius: $br-sm;
            }

            &.flippingTerm {
                animation: flipText 350ms ease-in-out;
    
                @keyframes flipText {
                    0% { transform: rotateX(0); }
                    49%, 52% { opacity: 1; }
                    50%, 51% { opacity: 0; }
                    50% { transform: rotateX(90deg); }
                    51% { transform: rotateX(270deg); }
                    100% { transform: rotateX(360deg); }
                }            
            }

            &.nextingTerm {
                animation: nextText 150ms ease-out;
    
                @keyframes nextText {
                    0% { transform: translateX(50px); }
                    100% { transform: translateX(0); }
                }
            }

            &.previngTerm {
                animation: prevText 150ms ease-out;
    
                @keyframes prevText {
                    0% { transform: translateX(-50px); }
                    100% { transform: translateX(0); }
                }
            }
        }
    }

    & .nav {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-gap: .5rem;

        & .item {
            display: flex;
            gap: .5rem;
            align-items: center;
            border-radius: $br-lg;
            padding: 1rem 2rem;
            font-size: $fs-lg;
            color: $c-black;
            font-weight: 600;
            text-decoration: none;
            background-color: $c-accent-white;
            cursor: pointer;
            transition: background-color $trans-time-sm, color $trans-time-sm;
            position: relative;
            overflow: hidden;

            & svg {
                font-size: 1.5rem;
            }

            &:after {
                content: '';
                position: absolute;
                width: 10px;
                height: 100%;
                background-color: $c-gray-100;
                bottom: 0;
                left: 0;
                transition: width $trans-time-sm;
            }
            &:hover:after {
                width: 15px;
            }
            &:nth-of-type(1) {
                &:hover {
                    color: $c-aqua-dark;
                }

                &:after {
                    background-color: $c-aqua;
                }
            }
            &:nth-of-type(2) {
                &:hover {
                    color: $c-yellow-dark;
                }

                &:after {
                    background-color: $c-yellow;
                }
            }
            &:nth-of-type(3) {
                &:hover {
                    color: $c-pink-dark;
                }

                &:after {
                    background-color: $c-pink;
                }
            }
            &:nth-of-type(4) {
                &:hover {
                    color: $c-green-dark;
                }

                &:after {
                    background-color: $c-green;
                }
            }
        }
    }

    & .footer {
        display: grid;
        place-items: center;

        & .footerNav {
            width: 60%;
            padding-inline: 1rem;
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            
            & .navSection {
                display: flex;
                align-items: center;
                gap: .5rem;

                &:nth-child(2) {
                    justify-content: center;
                }
                &:nth-child(3) {
                    justify-content: right;
                }

                & .item {
                    border-radius: 100%;
                    padding: .5rem;
                    line-height: 0;
                    display: grid;
                    place-items: center;
                    background-color: $c-accent-white;
                    cursor: pointer;
                    transition: background-color $trans-time-sm;

                    & svg {
                        width: 1.5rem;
                        height: 1.5rem;
                        min-width: 1.5rem;
                    }

                    &:hover {
                        background-color: $c-gray-100;
                    }

                    &.active {
                        background-color: $c-red;
                        color: $c-white;
                    }
                }

                & .flashcardsNav {
                    display: grid;
                    width: 75%;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: .5rem;
                    align-items: center;
                    justify-items: center;
                    text-align: center;
                    user-select: none;

                    & .item {
                        background-color: $c-accent-red;
                        border: 1px solid $c-red;
                        color: $c-red;
                        padding: .5rem;
                        font-size: .9rem;

                        &:hover {
                            background-color: darken($color: $c-accent-red, $amount: 5%);
                        }
                    }
                }
            }
        }
    }

    & .creator {
        margin-top: 3rem;
    }

    & .summary {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-inline: auto;
        margin-top: 3rem;

        & .term {
            display: grid;
            gap: 1rem;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: $c-accent-white;
            padding: 2rem 3rem;
            border-radius: $br-md;
        }
    }
}

@media screen and (max-width: 1000px) {
    .flashcards {
        & .main {
            grid-template-columns: 1fr 3fr;
        }

        & .footer {
            & .footerNav {
                width: 75%;
                margin-inline: auto 0;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .flashcards {
        & .main {
            grid-template-columns: 1fr;
        }

        & .footer {
            & .footerNav {
                width: 100%;
                margin-inline: 0;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .flashcards {
        & .footer {
            & .footerNav {
                padding: 0;
                margin: 0;

                & .navSection {
                    gap: 2px;
                    
                    & .item {
                        & svg {
                            width: 1.2rem;
                            height: 1.2rem;
                            min-width: 1.2rem;
                        }
                    }
                }
            }
        }

        & .summary {
            & .term {
                padding: 2rem;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .flashcards {
        & .footer {
            & .footerNav {
                grid-template-columns: 1fr 2fr 1fr;

                & .navSection {
                    & .flashcardsNav {
                        & span {
                            font-size: .75rem;
                        }
                    }
                }
            }
        }
    }
}