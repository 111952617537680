@import '/public/sass/variables';

.header {
    margin-bottom: 1rem;
}

.questionWrapper {
    display: grid;
    gap: 1rem;
}

.loading {
    padding-block: 2rem;
    justify-content: center;
}