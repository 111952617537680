@import '/public/sass/variables.scss';

.title {
    & .heading {
        margin-bottom: .25rem;
    }

    & .date {
        color: $c-gray-300;
        font-size: $fs-lg;
        display: block;
    }
}

.testSave {
    margin-top: 2rem;

    & .navigation {
        display: flex;

        & .displayType {
            display: flex;
            align-items: center;
            font-size: $fs-md;
            background-color: $c-accent-white;
            border-radius: $br-sm;
            overflow: hidden;
            border: 1px solid $c-accent-white;
            width: max-content;

            & .type {
                width: 10ch;
                text-align: center;
                cursor: pointer;
                transition: background-color $trans-time-sm, color $trans-time-sm;
                padding: .5rem;
                user-select: none;

                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &:hover {
                    background-color: $c-gray-100;
                }
                
                &.selected {
                    background-color: $c-white;
                }
            }
        }

        & .selectStudent {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 1rem;

            & .selectStudentInputContainer {
                position: relative;

                & .selectStudentInput {
                    border: 1px solid $c-gray-100;
                    border-radius: $br-sm;
                    padding: .5rem 1rem;
                    font-size: $fs-md;
                    width: max-content;
        
                    &:focus {
                        outline: 2px solid $c-blue;
                    }

                    &.active {
                        border-radius: $br-sm $br-sm 0 0;
                    }
                }

                & .selectStudentDropdown {
                    display: none;

                    &:has(span) {
                        display: block;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        background-color: $c-white;
                        border: 1px solid $c-gray-100;
                        border-top: none;
                        border-radius: 0 0 $br-sm $br-sm;
                        overflow: hidden;
                        z-index: 1;
                        max-height: calc(100% * 10);
                        overflow-y: auto;

                        & span {
                            display: block;
                            padding: .5rem 1rem;
                            cursor: pointer;
                            transition: background-color $trans-time-sm;

                            &:hover, &:focus {
                                background-color: $c-accent-white;
                            }
                        }
                    }
                }
            }
        }
    }

    .tableWrapper {
        width: 100%;
        overflow-x: auto;
    }

    .studentAnswers {
        margin-top: 2rem;

        & .title {
            margin-bottom: .5rem;
            display: flex;

            & .score {
                margin-left: 1ch;
                color: $c-gray-200;
            }
        }

        & .questionBoxWrapper {
            display: grid;
            gap: 1rem;
            align-items: center;
            margin-top: 1rem;
        }
    }

    @media screen and (max-width: 640px) {
        & .navigation {
            display: grid;
            gap: 1rem;

            & .selectStudent {
                margin-left: 0;
            }
        }
    }
}

.loading {
    display: grid;
    place-items: center;
    margin-top: 5rem;
}