@import '/public/sass/variables';

.content {
    display: grid;
    place-items: center;
    padding: .5rem 1rem;
    text-align: center;

    & .wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        & .star {
            color: $c-gray-100;
            cursor: pointer;
            transition: color $trans-time-sm;

            & .filled {
                color: $c-yellow;
            }
        }
    }
}