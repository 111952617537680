@import '/public/sass/variables';
@import '/public/sass/mixins';

.form {
    & .inputWrapper {
        display: flex;
        flex-direction: column;
        margin-block: 1rem;
        gap: .5rem;

        & input[type="text"] {
            margin-bottom: .5rem;
        }

        & .checkbox {
            display: flex;
            align-items: center;
            gap: .5rem;

            & svg {
                font-size: .75rem;
            }
        }
    }

    & .questions {
        margin-top: 2rem;


        & .questionWrapper {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        & .buttonWrapper {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            margin-top: .5rem;
        }
    }
}