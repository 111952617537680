@import '/public/sass/variables';
@import '/public/sass/mixins';

.wrapper {
    padding: 1rem 3rem;
    padding-left: calc($width-dashboard-sidebar + 3rem);
    background-color: $c-backdrop-white;
    min-height: calc(100vh - $height-navbar);
    transition: padding $trans-time-md, transform $trans-time-md;

    @media screen and (max-width: $bp-dashboard-sidebar) {
        padding-inline: 1rem;

        &.sidebarOpen::after {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba($color: #000000, $alpha: .25);
        }
    }
}