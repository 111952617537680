@import '/public/sass/variables';

.wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1000px) {
    .wrapper {
        gap: .5rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}