@import '/public/sass/variables';

.container {
    margin-top: 2rem;
    margin-inline: 2rem;

    & .header {
        display: flex;
        align-items: center;

        & .live {
            display: flex;
            align-items: center;
            padding: 5px;
            font-size: $fs-sm;
            line-height: 1em;
            color: $c-white;
            background-color: $c-red;
            border-radius: $br-sm;
            margin-right: 1rem;

            &::before {
                content: '';
                width: .75em;
                aspect-ratio: 1 / 1;
                background-color: $c-white;
                margin-right: 5px;
                border-radius: 100%;
                animation: live-flash 2s ease-in-out infinite;
    
                @keyframes live-flash {
                    0% {
                        background-color: $c-red;
                    }
                    50% {
                        background-color: $c-white;
                    }
                    100% {
                        background-color: $c-red;
                    }
                }
            }
        }
    }
}

.tableWrapper {
    width: 100%;
    overflow-x: auto;
}

.footer {
    background-color: $c-accent-white;
    padding: 1.5rem;
    margin-top: 1rem;
    border-radius: $br-md;
    width: max-content;

    & .title {
        font-size: $fs-lg;
        font-weight: 700;
    }

    & .actions {
        display: flex;
        gap: 1rem;
        margin-top: .75rem;

        & .code {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: $fs-lg;
            background-color: $c-gray-100;
            width: max-content;
            padding: .25rem .5rem;
            border-radius: $br-sm;
            letter-spacing: 2px;

            & .icon {
                font-size: $fs-xs;
                margin-left: .25rem;
            }
        }
    }
}

.codeModalContent {
    text-align: center;
    position: relative;
    padding: 3rem;

    & p {
        font-size: $fs-lg;

        & i {
            color: $c-red;
            font-style: normal;
        }
    }
    
    & .code {
        display: block;
        margin-top: 1rem;
        font-size: 2.5rem;
        background-color: $c-accent-white;
        width: max-content;
        margin-inline: auto;
        padding: .25rem .75rem;
        border-radius: $br-sm;
        letter-spacing: 5px;
    }

    & .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        font-size: $fs-lg;
    }
}

.endButtonContainer {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    width: max-content;
}