@import '/public/sass/variables';
@import '/public/sass/mixins';

.checkbox {
    display: flex;
    align-items: center;
    gap: .5rem;
    position: relative;
    cursor: pointer;
    font-size: $fs-md;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    & > .label a {
        color: $c-red;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    & > .checkmark {
        @include center-content;
    
        border: 1px solid $c-gray-100;
        border-radius: $br-sm;
        aspect-ratio: 1/1;
        transition: background-color $trans-time-sm, border-color $trans-time-sm;
    
        & > svg {
            opacity: 0;
            color: $c-white;
            width: 15px;
            height: 15px;
        }
    }
    &:hover input ~ .checkmark {
        background-color: $c-gray;
    }

    & > input:checked ~ .checkmark {
        background-color: $c-red;
        border-color: $c-red;

        & > svg {
            opacity: 1;
        }
    }
}