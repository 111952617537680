.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.textArea {
    width: 100%;
    height: 200px;
    resize: vertical;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.separatorInputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}
