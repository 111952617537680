@import '/public/sass/variables';

.question {
    $topline-height: .5rem;

    background-color: $c-accent-white;
    border-radius: $br-md;
    overflow: hidden;
    display: flex;
    padding-top: $topline-height;
    position: relative;

    & .main {
        width: 100%;
        padding: 1rem 1.5rem;

        & .title {
            display: flex;
            margin-bottom: 1rem;
            gap: 1rem;
            align-items: center;

            & .titleInput {
                width: 100%;
                font-size: $fs-md;
                padding: .5rem;
                background-color: transparent;
                border: none;
                border-bottom: 2px solid $c-gray-100;
                border-radius: 0;
                outline: none;

                &:focus {
                    border-bottom: 2px solid $c-blue-dark;
                }
            }

            & .typeSelect {
                border: 1px solid $c-gray-100;
                padding: .25rem .5rem;
                border-radius: $br-sm;
                font-size: $fs-sm;

                &:focus {
                    outline: 2px solid $c-blue-dark;
                }
            }
        }

        & .answerContent {
            & .answerInputRemovable {
                display: flex;
                align-items: center;
                gap: .5rem;
                cursor: pointer;
                margin-bottom: .25rem;

                & .inputWrapper {
                    width: 100%;
                }

                & svg {
                    color: $c-gray-300;
                    transition: color $trans-time-sm;
                    &:hover {
                        color: $c-black;
                    }
                }
            }

            & .textarea {
                width: 100%;
                font-size: $fs-md;
                background-color: $c-white;
                border-radius: $br-sm;
                padding: .5rem 1rem;
                resize: none;
            }

            & .radioInputRemovable, & .checkboxInputRemovable {
                display: flex;
                margin-block: .25rem;
                margin-left: 1rem;

                & .radioWrapper, & .checkboxWrapper {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    margin-bottom: .25rem;
                    width: clamp(400px, max-content, 100%);

                    & .radio {
                        width: 18px;
                        aspect-ratio: 1/1;
                        background-color: transparent;
                        border-radius: 1rem;
                        line-height: 0;
                        cursor: pointer;
                        border: 2px solid $c-red;
                        position: relative;

                        &.selected::after {
                            content: '';
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background-color: $c-red;
                            border-radius: 1rem;
                        }
                    }

                    & .checkbox {
                        width: 22px;
                        aspect-ratio: 1/1;
                        background-color: transparent;
                        line-height: 0;
                        cursor: pointer;
                        border: 1px solid $c-gray-200;
                        border-radius: $br-sm;
                        position: relative;
                        transition: background-color $trans-time-sm;

                        &:hover {
                            background-color: $c-gray-100;
                        }

                        &.selected {
                            background-color: $c-red;
                            border-color: $c-red;
                        }

                        &.selected::after {
                            content: '✓';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: white;
                        }
                    }

                    & input {
                        background-color: transparent;
                        border: 0;
                        border-radius: 0;
                        font-size: $fs-md;
                        width: 100%;

                        &:focus {
                            outline: none;
                        }
                    }
                }

                & svg {
                    color: $c-gray-300;
                    transition: color $trans-time-sm;
                    cursor: pointer;
                    &:hover {
                        color: $c-black;
                    }
                }
            }

            & .addAnswer {
                cursor: pointer;
                color: $c-gray-300;
                transition: color $trans-time-sm;
                display: flex;
                align-items: center;
                font-size: $fs-sm;
                margin-top: .5rem;
                width: max-content;

                &:hover {
                    color: $c-black;
                }
            }

            & .answerOptions {
                display: flex;
                gap: .5rem 1rem;
                margin-top: 2rem;
                flex-wrap: wrap;
            }
        }
    }

    & .aside {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem .5rem;
        gap: 1rem;
        border-left: 1px solid $c-gray-100;

        & svg {
            font-size: 1.25rem;
            color: $c-gray-300;
            cursor: pointer;
            transition: background-color $trans-time-sm;
            padding: 1px;
            border-radius: 3px;

            &:hover {
                background-color: $c-gray-100;
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: $topline-height;
        background-color: $c-blue-dark;
        top: 0;
        left: 0;
    }
}