@import '/public/sass/variables';

.shortcut {
    aspect-ratio: 3/2;
    border-radius: $br-md;
    cursor: pointer;
    position: relative;
    font-size: $fs-xl;
    overflow: hidden;
    transition: translate $trans-time-sm;
    text-align: center;
    text-decoration: none;

    &:hover {
        @media (prefers-reduced-motion: no-preference) {
            translate: 0 -5%;
        }

        .pattern {
            opacity: 0.1;
        }
    }
}

.pattern {
    scale: 1.25;
    object-position: center;
    overflow: hidden;
    opacity: 0;
    transition: opacity $trans-time-sm;
    pointer-events: none;
}

.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
}

@media screen and (max-width: 1000px) {
    .shortcut {
        font-size: $fs-lg;
    }
}

@media screen and (max-width: 800px) {
    .shortcut {
        aspect-ratio: 4/2;
    }
}

@media screen and (max-width: 500px) {
    .shortcut {
        aspect-ratio: 5/2;
    }
}