@import '/public/sass/variables';

.button {
    background-color: $c-red;
    padding: .5rem 1rem;
    border: none;
    border-radius: $br-sm;
    color: $c-white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color $trans-time-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    text-align: center;
    position: relative;

    &:hover {
        background-color: $c-red-dark;
    }

    &:active {
        transition: none;
        background-color: $c-red;
    }
    
    &:focus {
        transition: none;
        outline: adjust-color($color: $c-red, $alpha: -0.5) 2px solid;
    }

    &:disabled {
        background-color: adjust-color($color: $c-red, $saturation: -50%);
        cursor: not-allowed;
    }

    &.loading {
        & .text {
            opacity: 0;
            pointer-events: none;
        }
    }

    & .loader {
        position: absolute;
    }
}