// Colors
$c-black: #262626;
$c-white: #ffffff;

$c-gray: #d7dbe7;
$c-gray-100: #cacfde;
$c-gray-200: #a9aebf;
$c-gray-300: #898e9f;

$c-red: #FE654F;
$c-red-dark: #da5342;

$c-blue: #D8EBFC;
$c-blue-dark: #AECDFF;

$c-aqua: #9EDAFF;
$c-aqua-dark: #84bfe4;

$c-yellow: #FFE76E;
$c-yellow-dark: #ddc85d;

$c-pink: #FFB8F4;
$c-pink-dark: #da99d0;

$c-green: #94FFB6;
$c-green-dark: #72d391;

$c-error: #fdcbcb;
$c-error-dark: #fba5a5;

$c-success: #cdfedd;
$c-success-dark: #7fdf9d;

$c-info: #c3e7f8;
$c-info-dark: #82c9e5;

$c-warning: #ffecb8;
$c-warning-dark: #e6cb82;

$c-accent-white: #e3e7f5;
$c-accent-red: #fde7e4;

$c-backdrop-white: #ececf3;

// Widths and heights
$w-lg: min(1200px, 100% - 3rem);
$w-sm: min(800px, 100% - 3rem);

$height-navbar: 100px;

$width-dashboard-sidebar: max(20%, 300px);

// Borders
$br-lg: 15px;
$br-md: 10px;
$br-sm: 7px;

// Transitions and animations
$trans-time-sm: 150ms;
$trans-time-md: 300ms;
$trans-time-lg: 500ms;

// Text
$fs-xs: .75rem;
$fs-sm: .85rem;
$fs-md: 1rem;
$fs-lg: 1.25rem;
$fs-xl: 1.5rem;
$fs-xxl: 2rem;
$fs-3l: 2.5rem;
$fs-4l: 3rem;
$fs-5l: 4rem;

// Breaking points
$bp-dashboard-sidebar: 1000px;