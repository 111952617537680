@import '/public/sass/variables';

.button {
    background-color: $c-red;
    padding: .75rem 1rem;
    border: none;
    border-radius: $br-sm;
    color: $c-white;
    font-size: $fs-lg;
    cursor: pointer;
    transition: background-color $trans-time-sm;
    margin-block: 1rem;
    width: 100%;

    &:hover {
        background-color: $c-red-dark;
    }

    &:active {
        transition: none;
        background-color: $c-red;
    }
    
    &:focus {
        transition: none;
        outline: adjust-color($color: $c-red, $alpha: -0.5) 2px solid;
    }

    &:disabled {
        background-color: adjust-color($color: $c-red, $saturation: -50%);
        cursor: not-allowed;
    }
}