@import '/public/sass/variables';

.menu {
    position: fixed;
    background-color: $c-white;
    top: calc($height-navbar - 1rem);
    right: calc((100vw - $w-lg) / 2);
    display: none;
    flex-direction: column;
    border: 1px solid $c-gray-100;
    border-radius: $br-md;
    overflow: hidden;
    z-index: 100;
    min-width: 250px;

    & > .header {
        padding: 1rem 1rem .5rem 1rem;
        border-bottom: 1px solid $c-gray-100;
        display: flex;
        align-items: center;
        column-gap: .5rem;
        line-height: 1em;

        & > .user {
            display: flex;
            flex-direction: column;

            & > .username {
                font-size: $fs-md;
            }

            & > .email {
                font-size: $fs-xs;
                color: $c-gray-300;
            }
        }

    }

    & > .section {
        display: flex;
        flex-direction: column;
        padding: .5rem;
        
        &:not(:last-child) {
            border-bottom: 1px solid $c-gray-100;
        }

        & > .item {
            width: 100%;
            padding-inline: .5rem;
            padding-block: .25rem;
            display: flex;
            align-items: center;
            column-gap: .5rem;
            line-height: 1em;
            cursor: pointer;
            border-radius: $br-sm;
            transition: background-color $trans-time-sm;
            text-decoration: none;

            &:hover {
                background-color: $c-accent-white;
            }
        }
    }

    &.open {
        display: flex;
        opacity: 0;
        translate: 0 -10px;
        animation: open-nav $trans-time-sm forwards;
    }
    &:not(.open) {
        animation: close-nav $trans-time-sm forwards;
    }
}

@keyframes open-nav {
    to {
        opacity: 1;
        translate: 0 0;
    }
}

@keyframes close-nav {
    from {
        opacity: 1;
        display: flex;
    }
    to {
        opacity: 0;
        translate: 0 -10px;
        display: none;
    }
}