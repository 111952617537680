@import '/public/sass/variables';

.content {
    text-align: center;
    display: grid;
    justify-items: center;
    
    & h1 {
        margin-top: 1rem;
    }

    & .icon {
        font-size: 4rem;
        color: $c-warning-dark;
    }

    & .buttonWrapper {
        margin-top: 3rem;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}