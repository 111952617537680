@import '/public/sass/variables';
@import '/public/sass/mixins';

.form {
    & .inputWrapper {
        display: flex;
        flex-direction: column;
        margin-block: 1rem;
        gap: 1rem;
    }
}