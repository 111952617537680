@import '/public/sass/variables';

.container {
    background-color: $c-white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    & .testNav {
        position: absolute;
        background-color: $c-accent-white;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 2rem;

        & .back {
            cursor: pointer;
        }

        & span {
            font-weight: 700;
        }
    }
    
    & .test {
        width: $w-lg;
        margin-inline: auto;
        min-height: 100dvh;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-block: 5rem 1rem;


        & .timer {
            background-color: $c-pink;
            padding: 1rem;
            border-radius: $br-md;
            color: white;
            text-align: center;
        }
        
        & .cardWrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: .5rem;
            flex: 1;
    
            & .card {
                border: 2px solid adjust-color($color: $c-pink, $lightness: 7%);
                border-radius: $br-md;
                display: grid;
                place-items: center;
                text-align: center;
                padding: .5rem;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                user-select: none;
    
                &:hover {
                    background-color: adjust-color($color: $c-pink, $lightness: 10%);
                }
    
                &.selected {
                    background-color: adjust-color($color: $c-pink, $lightness: 7%);
                }
    
                &.matched {
                    pointer-events: none;
                    animation: matched 150ms forwards;
    
                    @keyframes matched {
                        to {
                            scale: .75;
                            opacity: 0;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 700px) {
            & .cardWrapper {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(6, 1fr);
            }
        }
    
        & .endScreen {
            display: grid;
            place-items: center;
            flex: 1;
    
            & .notAuthed {
                display: grid;
                text-align: center;
                width: 100%;
    
                & .time {
                    font-size: $fs-xl;
                    font-weight: 700;
                    margin-bottom: 1rem;
                }
    
                & .message {
                    margin-bottom: 3rem;
                }
            }
    
            & .topList {
                display: flex;
                flex-direction: column;
                gap: .5rem;
    
                & .user {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: adjust-color($color: $c-pink, $lightness: 10%);
                    padding: .5rem 1rem;
                    border-radius: $br-md;
    
                    & .medal {
                        border: 1px solid $c-pink;
                        border-radius: 100vw;
                        padding: .5rem;
                        font-size: $fs-sm;
                        width: 32px;
                        line-height: 0;
                        aspect-ratio: 1/1;
                        display: grid;
                        place-items: center;
                        color: $c-pink;
                        font-weight: 700;
                    }
    
                    & .username {
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                    }
                }
            }
    
            & .topListNav {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                width: 100%;
                margin-top: .5rem;
    
                & .pageNumber {
                    text-align: center;
                    color: $c-gray-300;
                }
    
                & .arrowButton {
                    & svg {
                        width: 32px;
                        height: 32px;
                        color: $c-gray-100;
                        transition: color $trans-time-sm;
                        cursor: pointer;
    
                        &:hover {
                            color: $c-gray-200;
                        }
                    }
    
                    &:first-of-type {
                        justify-self: start;
                    }
                    &:last-of-type {
                        justify-self: end;
                    }
                }
            }
        }
    }
}