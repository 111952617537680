@import '/public/sass/variables';

.form {
    text-align: center;
    display: grid;
}

.inputWrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: max-content;
    gap: .25rem;
    margin-inline: auto;
    margin-block: 1.5rem;
    border-radius: $br-sm;
    position: relative;

    &.loading {
        $--border-width: 4px;

        overflow: hidden;
        padding: $--border-width;
        border-radius: calc($br-sm + $--border-width);

        &::before {
            content: '';
            position: absolute;
            width: 120%;
            aspect-ratio: 1 / 1;
            border-radius: 100%;
            background: conic-gradient(
                #ea4335 0.0turn 0.25turn, 
                transparent 0.25turn 1turn,
            );
            z-index: -2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: spin 3s infinite linear forwards;

            @keyframes spin {
                from {transform: translate(-50%, -50%) rotate(0);}
                to   {transform: translate(-50%, -50%) rotate(360deg);}
            }
        }

        &::after {
            content: '';
            background-color: $c-accent-white;
            width: calc(100% - $--border-width * 2);
            height: calc(100% - $--border-width * 2);
            top: $--border-width;
            left: $--border-width;
            border-radius: $br-sm;
            position: absolute;
            z-index: -1;
        }
    }

    & .input {
        width: 1em;
        font-size: $fs-xl;
        text-align: center;
        padding: 1rem .75rem;
        box-sizing: content-box;
        border: none;
        background-color: $c-accent-white;
        border-radius: $br-sm;

        &:focus {
            outline: 2px solid adjust-color($color: $c-blue-dark, $alpha: 0.5);
        }
    }
}

.nameInputWrapper {
    margin-bottom: 2rem;
    margin-top: 2rem;

    & input {
        display: block;
    }
}

.illustration {
    width: clamp(200px, 25dvw, 500px);
}