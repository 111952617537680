@import '/public/sass/variables';

// Containers
@mixin container-lg() {
    width: $w-lg;
    margin-inline: auto;
    flex: 1;
}

@mixin container-sm() {
    width: $w-sm;
    margin-inline: auto;
    flex: 1;
}

@mixin section() {
    padding-block: 2rem;
}

@mixin center-content() {
    display: grid;
    place-items: center;
}

// Inputs and forms
@mixin normal-input() {
    width: 100%;
    padding: .5rem 1rem;
    border: 1px solid $c-gray;
    border-radius: $br-sm;
    font-size: $fs-md;

    &:focus {
        outline: 2px solid adjust-color($color: $c-blue-dark, $alpha: 0.5);
    }
}

@mixin normal-label() {
    font-size: $fs-sm;
    line-height: 1em;
    margin-bottom: 5px;

    &::placeholder {
        color: $c-red;
    }
}

// Message popups
@mixin message-popup($background-color, $border-color) {
    background-color: $background-color;
    border: 1px solid $border-color;

    padding: .75rem;
    margin-block: 1rem;
    border-radius: $br-sm;
    display: flex;
    align-items: center;
    gap: .5rem;
    animation: open-message $trans-time-sm forwards;

    & > .icon {
        font-size: $fs-sm;
        color: adjust-color($color: $border-color, $lightness: -20%, $saturation: -10%);
    }

    & > .message {
        line-height: 1em;
    }

    @keyframes open-message {
        0% {
            scale: .95;
        } 100% {
            scale: 1;
        }
    }
}
