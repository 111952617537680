@import '/public/sass/variables';

.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 1rem;

    @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
    }
}