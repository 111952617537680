@import '/public/sass/variables';

.userLink {
    display: flex;
    align-items: center;
    gap: .75rem;
    text-decoration: none;
    width: max-content;

    & .username {
        font-size: $fs-lg;
    }
}