@import '/public/sass/variables';
@import '/public/sass/mixins';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.buttons {
    margin-top: .5rem;
    display: flex;
    justify-content: right;
}

.placeholder {
    height: 50px;  // Adjust this based on the card height
    background-color: rgba(0, 0, 0, 0.1);  // Light gray placeholder
    border: 2px dashed #ccc;  // Dashed border to indicate the drop area
    margin: 10px 0;
}