@import '/public/sass/variables';

.set {

    & .top {
        display: flex;
        align-items: center;

        & .subject {
            font-size: $fs-md;
            background-color: $c-gray-100;
            border-radius: 100px;
            padding: .2rem 1rem;
            color: $c-black;
            margin-right: 1rem;
        }
    }

    & .name {
        font-size: $fs-3l;
        font-weight: 700;
        margin-top: .5rem;
        display: block;
        margin-bottom: 2rem;
    }
}