@import '/public/sass/variables';

.done {
    text-align: center;
    padding-bottom: 5rem;

    & .header {
        font-size: clamp($fs-3l, 5vw, $fs-4l);
        font-weight: 900;
        text-wrap: balance;
        line-height: 1em;
    }
}