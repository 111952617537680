@import '/public/sass/variables';

.content {
    
    & .item {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 7px;
        padding: .75rem 1rem;
        transition: background-color $trans-time-sm;
        
        &:hover {
            background-color: $c-accent-white;
        }
        
        &:first-child {
            border-radius: calc(.5rem - 1px) calc(.5rem - 1px) 0 0;
        }

        &:last-child {
            border-radius: 0 0 calc(.5rem - 1px) calc(.5rem - 1px);
        }

        & svg {
            font-size: .8rem;
        }
    }
}