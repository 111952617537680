@import '/public/sass/variables';
@import '/public/sass/mixins';

.container {
    background-color:#ff99f5;
    background-image:
        radial-gradient(at 20% 2%, hsla(355,93%,74%,1) 0px, transparent 50%),
        radial-gradient(at 71% 76%, hsla(60,71%,78%,1) 0px, transparent 50%),
        radial-gradient(at 43% 59%, hsla(355,99%,67%,1) 0px, transparent 50%),
        radial-gradient(at 82% 87%, hsla(129,90%,75%,1) 0px, transparent 50%),
        radial-gradient(at 99% 6%, hsla(99,67%,76%,1) 0px, transparent 50%),
        radial-gradient(at 22% 4%, hsla(232,90%,67%,1) 0px, transparent 50%),
        radial-gradient(at 33% 83%, hsla(184,86%,63%,1) 0px, transparent 50%);
    flex: 1;
}

.centerContent {
    @include center-content();
}