@import '/public/sass/variables';

.footer {
    padding: 3rem 2rem;
    margin-top: 2rem;
    border-top: 1px solid $c-gray-100;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: $w-lg;
    margin-inline: auto;
}

.branding {
    display: flex;
    flex-direction: column;

    & > a {
        width: max-content;

        & img {
            max-width: 200px;
            margin-bottom: 1rem;
        }
    }
}

.links {
    display: grid;
    gap: 1rem;
}

@media screen and (max-width: 600px) {
    .content {
        grid-template-columns: 1fr;
        gap: 4rem;
    }
}