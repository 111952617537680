@import '/public/sass/variables';

.box {
    padding: 2rem 3rem;
    border-radius: $br-md;
    background-color: $c-white;
    width: min(100% - 2rem, 500px);
    margin-block: 2rem;

    @media (max-width: 600px) {
        padding: 2rem;
    }
}

.title {
    margin-block: 2rem;
    text-align: center;
}

.inputWrapper {
    display: grid;
    row-gap: 1rem;
}

.link {
    width: max-content;
    font-size: $fs-sm;
    margin-block: .5rem;
    margin-left: auto;
    display: block;
    color: $c-gray-300;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.checkbox {
    display: block;
    margin-block: 1rem;
}

.footer {
    text-align: center;
    width: 100%;
    display: block;
    color: $c-gray-300;
    text-decoration: none;
    margin-block: 2rem 1rem;

    & > i {
        font-style: normal;
        color: $c-black;
    }

    &:hover > i {
        text-decoration: underline;
    }
}