@import '/public/sass/variables';

.featureSections {
    margin-block: 15dvh;

    & .setsSection .paragraphBox,
    & .testsSection .paragraphBox {
        padding: 1rem 1.5rem;
        border-radius: $br-md;
        margin-top: 2rem;
        
        & .paragraph {
            line-height: 1.5em;
        }
    }

    & .setsSection {
        & .titleGraphics {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;

            & .title {
                font-size: clamp($fs-3l, 5vw, $fs-4l);
                font-weight: 900;
                color: darken($c-aqua, 50%);
                width: 75%;
                text-wrap: balance;
                line-height: 1em;
            }

            & .graphicsGrid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                gap: 2rem;

                & span {
                    display: flex;
                    gap: 1.5rem;
                    align-items: center;
                    font-size: $fs-lg;
                    font-weight: 700;

                    & img {
                        width: 100px;
                        min-width: 100px;
                    }
                }
            }
        }

        & .paragraphBox {
            background-color: lighten($c-aqua, 10%);
        }
    }

    & .testsSection {
        margin-top: 20dvh;

        & .title {
            font-size: clamp($fs-3l, 5vw, $fs-4l);
            font-weight: 900;
            color: adjust-color($c-green, $lightness: -50%, $saturation: -50%);
            text-wrap: balance;
            line-height: 1em;
            text-align: center;
        }

        & .graphicsSubtitle {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 3rem;
            align-items: center;
            margin-top: 3rem;

            & p {
                margin-block: .5rem 0;
            }
        }

        & .paragraphBox {
            background-color: lighten($c-green, 10%);
        }
    }

    @media screen and (max-width: 860px) {
        & .setsSection .titleGraphics {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            
            & .title {
                width: 100%;
                text-align: center;
            }

            & .graphicsGrid {
                display: flex;
                justify-content: space-between;
                width: 100%;

                & span {
                    display: flex;
                    gap: 1rem;

                    & img {
                        width: 50px;
                        min-width: 50px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 700px) {
        & .setsSection .titleGraphics {
            & .graphicsGrid {
                & span {
                    & > span {
                        display: none;
                    }

                    & img {
                        width: 100%;
                    }
                }
            }
        }

        & .testsSection .graphicsSubtitle {
            grid-template-columns: 1fr;
            gap: 2rem;

            & div:has(img) {
                width: min(100%, 500px);
                justify-self: center;
            }
        }
    }
}

.whySection {
    margin-block: 15dvh 5vh;
    display: grid;
    grid-template-columns: 20vw 1fr;
    gap: 3rem;

    & .content {
        padding-top: 2rem;

        & .title {
            font-size: $fs-4l;
            font-weight: 900;
            color: adjust-color($c-yellow-dark, $lightness: -15%, $saturation: 20%);
            text-wrap: balance;
            line-height: 1em;
            margin-bottom: 1.5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: 30vw 1fr;
    }

    @media screen and (max-width: 860px) {
        grid-template-columns: 1fr;
        gap: 1rem;

        & :has(img) {
            max-width: 350px;
            justify-self: center;
        }

        & .content {
            & .title {
                font-size: clamp($fs-3l, 5vw, $fs-4l);
            }
        }
    }
}

.startSection {
    margin-block: 5vh;

    & .buttonWrapper {
        display: grid;
        justify-content: center;

        & button {
            width: max-content;
            padding-inline: 2rem;
        }
    }
}