@import '/public/sass/variables';

.table {
    width: 100%;
    min-width: 100%;
    margin-top: 2rem;
    table-layout: fixed;

    & > thead {
        text-align: left;

        & th {
            background-color: $c-info;
            border: 1px solid $c-info-dark;
        }
    }

    & td, & th {
        padding: .5rem .75rem;
        font-size: $fs-md;
        background-color: $c-white;
        border: 1px solid $c-gray-200;
        border-radius: $br-sm;
        min-width: 100px;
        transition: background-color $trans-time-sm;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.correct {
            background-color: $c-success;
            border: 1px solid $c-success-dark;
        }

        &.incorrect {
            background-color: $c-error;
            border: 1px solid $c-error-dark;
        }
    }

    & .nameCol {
        width: 15em;
        box-sizing: content-box;
    }

    & .scoreCol {
        width: 4em;
        box-sizing: content-box;
    }

    & .questionCol {
        width: 7em;
        box-sizing: content-box;
    }

    & .totalRow {
        & td {
            background-color: $c-info;
            border: 1px solid $c-info-dark;
        }
    }

    & .studentRow {
        & .studentName {
            display: flex;

            & .status {
                display: inline-block;
                width: 10px;
                aspect-ratio: 1 / 1;
                border-radius: 100%;
                background-color: $c-info;
                align-self: flex-start;
                margin-left: 4px;
                margin-top: 2px;
                cursor: pointer;
                
                &.done {
                    background-color: $c-green-dark;
                }
            }

            & .frozenIcon {
                color: $c-red;
                cursor: pointer;
                margin-left: auto;
            }
        }
    }
}

// Popups
.frozenPopup {
    padding: .5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.answerPreview {
    padding: .5rem 1rem;

    & .exactAnswer, & .openAnswer {
        line-height: 1.5em;
    }

    & .mcAnswer {
        display: grid;
        grid-template-columns: 1rem 1fr;
        column-gap: .75rem;
        
        & .radio {
            display: inline-block;
            width: 1rem;
            aspect-ratio: 1 / 1;
            border-radius: 100%;
            background-color: transparent;
            border: 2px solid $c-red;
            position: relative;
            margin-top: 2px;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                aspect-ratio: 1 / 1;
                border-radius: 100%;
                background-color: $c-red;
                transform: translate(-50%, -50%);
            }
        }
    }

    & .checkboxAnswer {
        display: grid;
        grid-template-columns: 1rem 1fr;
        column-gap: .75rem;

        & .checkbox {
            display: inline-block;
            width: 1rem;
            aspect-ratio: 1 / 1;
            border-radius: 4px;
            background-color: transparent;
            border: 2px solid $c-red;
            position: relative;
            margin-top: 2px;
            background-color: $c-red;

            & svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $c-white;
            }
        }
    }
}